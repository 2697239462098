import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import { TextMotionDiv } from './TextMotionDiv';

export class Description extends React.Component {
    constructor(props) {
        super(props);
    }   
    render() {
        return (            
            <div style={styles.description} className='my-2 my-md-3'>                
                <div style={styles.label}>
                    {this.props.label}
                </div>
                <div style={styles.value}>
                    {this.props.value}
                </div>
            </div>
        );
    }
}

Description = Radium(Description);

var styles = {
    description: {    
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        color: '#000',         
        backgroundColor: '#fff',   
        width: '100%',
        height: '100%',
        '@media(max-width: 767.98px)': {         
            fontSize: '1.1rem',
            lineHeight: '1.6rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.25rem',
            lineHeight: '1.8rem',            
        },
        fontSize: '1.5rem',
        lineHeight: '2.0rem',             
    },    
    label: {        
        fontWeight: '300',
        color: '#999',
        textTransform: 'uppercase',
        display: 'flex',                
        '@media(max-width: 767.98px)': {
            fontSize: '0.8rem',
            lineHeight: '1.6rem',
            letterSpacing: '0.04rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '0.85rem',
            lineHeight: '1.8rem',   
            letterSpacing: '0.045rem',
        },
        fontWeight: 300,
        fontSize: '0.9rem',
        lineHeight: '2.0rem',
        letterSpacing: '0.05rem',        
    },
    value: {

        fontWeight: '400',        
    }
}