import Radium from 'radium';
import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import axios from 'axios';

import { LandingPage } from './LandingPage';
import { MapPage } from './MapPage';
import { PopupMenu } from './PopupMenu';
import { NewsOverviewPage } from './NewsOverviewPage';
import { NewsDetailPage } from './NewsDetailPage';

import './App.css';
import { CustomPage } from './CustomPage';
import { DonatePage } from './DonatePage';

export const RESTBase = 'https://verpfl.uber.space/wordpress/wp-json/wp/v2';
let categoryMap = [];

export function parseWPCategories(categories) {
    let ret = '';    
    for (const categoryIndex in categories) {
        ret += categoryMap[categories[categoryIndex]];        
        if (categoryIndex < categories.length - 1) {
            ret += ', ';
        }
    }    
    return ret;
}

export function parseWPPage(page, picture) {    
    return {
        id: page.id,
        title: page.title.rendered,
        published: page.status === 'publish',        
        content: page.content.rendered,        
        pictureURL: picture
    };
}

export function parseWPPost(post, categories, picture) {
    let excerpt = post.excerpt.rendered;
    let index = -1;
    if ((index = excerpt.indexOf('&hellip;')) >= 0){
        excerpt = excerpt.substring(0, index) + '</p>';
    }    
    return {
        id: post.id,
        title: post.title.rendered,    
        published: post.status === 'publish',
        featured: post.sticky,
        content: post.content.rendered,
        subTitle: categories,
        teaser: excerpt,  
        pictureURL: picture
    };
}

export async function fetchWPVideos() {    
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/seitenvideo', {})
        .then(async function(response){
            if (response.status === 200) {
                let videos = [];
                for (const video of response.data) {                
                    videos.push({
                        title: video.title.rendered,
                        videoURL: video.video ? video.video.guid : '',
                        placeholderURL: video.placeholder ? video.placeholder.guid : ''
                    });
                }
                resolve(videos);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPImages() {    
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/seitenbilder', {})
        .then(async function(response){            
            if (response.status === 200) {
                let images = [];                
                for (const image of response.data) {                
                    images.push({
                        title: image.title.rendered,                        
                        pictureURL: image.picture ? image.picture.guid : ''
                    });
                }
                resolve(images);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPTexts() {    
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/seitentext', {})
        .then(async function(response){
            if (response.status === 200) {
                let texts = [];
                for (const text of response.data) {                
                    texts.push({
                        title: text.title.rendered,
                        content: text.text_content
                    });
                }
                resolve(texts);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

function parseIconStyle(style) {
    return style === 'Baum' ? 'tree' 
    : style === 'Ort' ? 'location'
    : style === 'Dokument' ? 'tree-document'
    : style === 'Spenden' ? 'donate'
    : style === 'Quittung' ? 'receipt'
    : 'none';
}

export async function fetchWPDonateInfo() {    
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/spendenseite', {})
        .then(async function(response){
            if (response.status === 200) {
                let info = [];
                for (const entry of response.data) {                    
                    info.push({
                        title: entry.title.rendered,
                        description: entry.description,
                        isTitle: entry.is_title === '1',
                        phoneNumber: entry.phone_number,
                        iconStyle: entry.icon && entry.icon.length > 0 
                        ? parseIconStyle(entry.icon[0].post_title) 
                        : 'none',
                        emailAddress: entry.email_address,
                        order: entry.order ? entry.order : 1000
                    });
                }
                resolve(info);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPTrees() {    
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/baum', {})
        .then(async function(response){
            if (response.status === 200) {
                let trees = [];
                for (const tree of response.data) {
                    let coordinates = [];
                    let splitWPCoordinates = tree.coordinates.split(',');
                    if (splitWPCoordinates.length === 2) {
                        coordinates.push(parseFloat(splitWPCoordinates[0]));
                        coordinates.push(parseFloat(splitWPCoordinates[1]));
                    }
                    trees.push({
                        title: tree.title.rendered,
                        location: tree.location,
                        description: tree.description,
                        code: tree.code,
                        coordinates: coordinates,
                        pictureUrl: tree.picture ? tree.picture.guid : ''
                    });
                }
                resolve(trees);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPCategories(categories) {
    let ret = '';
    for (const categoryIndex in categories) {
        await axios.get(RESTBase + '/categories/' + categories[categoryIndex])
        .then(function(response) {            
            if (response.status === 200) {
                ret += response.data.name;
                if (categoryIndex < categories.length - 1) {
                    ret += ', ';
                }
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    }    
    return ret;
}

export async function fetchWPPicture(picture) {
    let ret = '';
    await axios.get(RESTBase + '/media/' + picture)
    .then(function(response) {            
        if (response.status === 200) {
            ret = response.data.guid.rendered;
        }
    }.bind(this))
    .catch(function(error) {
        console.log(error);
    }.bind(this));
    return ret;
}

export async function fetchWPPages() {
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/pages', {})
        .then(async function(response){
            if (response.status === 200) {
                let pages = [];
                for (const page of response.data) {                                    
                    const picture = page.featured_media > 0 
                    ? await fetchWPPicture(page.featured_media)
                    : null; 
                    let parsedPage = parseWPPage(page, picture);
                    if (parsedPage.published) {
                        pages.push(parsedPage);
                    }
                }
                resolve(pages);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPSinglePage(id) {
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/pages/' + id, {})
        .then(async function(response){
            if (response.status === 200) {
                const page = response.data;
                console.log(page);    
                const picture = page.featured_media > 0 
                ? await fetchWPPicture(page.featured_media)
                : null; 
                let parsedPage = parseWPPage(page, picture);                        
                resolve(parsedPage);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPSinglePost(id) {
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/posts/' + id, {})
        .then(async function(response){
            if (response.status === 200) {
                let post = response.data;            
                console.log(post);
                const categories = await fetchWPCategories(post.categories);
                const picture = post.featured_media > 0 
                ? await fetchWPPicture(post.featured_media)
                : null; 
                let parsedPost = parseWPPost(post, categories, picture);                     
                resolve(parsedPost);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPPosts() {
    return new Promise(function(resolve, reject) {
        axios.get(RESTBase + '/posts', {})
        .then(async function(response){
            if (response.status === 200) {
                let posts = [];
                for (const post of response.data) {                        
                    const categories = await fetchWPCategories(post.categories);
                    const picture = post.featured_media > 0 
                    ? await fetchWPPicture(post.featured_media)
                    : null; 
                    let parsedPost = parseWPPost(post, categories, picture);
                    if (parsedPost.published) {
                        posts.push(parsedPost);                        
                    }
                }
                resolve(posts);
            }
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    });
}

export async function fetchWPFeaturedPosts() {
    return new Promise(function(resolve, reject) {
        fetchWPPosts().then(function(posts){
            let featuredPosts = [];
            for (const post of posts) {
                if (post.featured) {
                    featuredPosts.push(post);
                }
            }
            resolve(featuredPosts);
        });
    });
}

export async function fetchWPUnfeaturedPosts() {    
    return new Promise(function(resolve, reject) {
        fetchWPPosts().then(function(posts){
            let unfeaturedPosts = [];
            for (const post of posts) {
                if (!post.featured) {
                    unfeaturedPosts.push(post);
                }
            }            
            resolve(unfeaturedPosts);
        });
    });
}

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        };
    }
    togglePopupMenu() {        
        this.setState({
            showMenu: !this.state.showMenu
        });
    }
    render() {
        return (
            <>
            <div style={{width: '100%', height: '100%', backgroundColor: '#010101',
            position: 'absolute', zIndex: -1000}}>
            </div>              
            <Container fluid style={styles.body}>   
                <Router>
                    <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                    integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                    crossorigin="anonymous"
                    />    
                     <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
                    integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
                    crossorigin=""/>
                    <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
                    integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
                    crossorigin=""></script>
                    <PopupMenu togglePopupMenu={this.togglePopupMenu.bind(this)} 
                    showMenu={this.state.showMenu}/>                                           
                    <Switch>                                                                    
                        <Route exact path='/articles'>
                            <NewsOverviewPage togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        </Route>                        
                        <Route exact path='/news/:id' render={(props) =>
                            <NewsDetailPage {...props} togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        }/>
                        <Route exact path='/page/:id' render={(props) =>
                            <CustomPage {...props} togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        }/>
                        <Route exact path='/donate'>
                            <DonatePage togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        </Route>  
                        <Route exact path='/map'>
                            <MapPage togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        </Route>  
                        <Route exact path='/'>
                            <LandingPage togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        </Route>                                            
                    </Switch>    
                </Router>             
            </Container> 
            </>
        );
    }
}


var styles = {
    body: {
        fontFamily: '"Poppins", sans-serif',
    },  
};