import React from 'react';
import Radium from 'radium';

export class ArrowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        };        
        if (this.props.variant === 'sled') {
            this.paddingLeftHover = '1em';
            this.paddingLeftStandard = '0.5em';
        } else {
            this.paddingLeftHover = '1.5em';
            this.paddingLeftStandard = '1.0em';
        }
    }
    render() {
        return (
            <>                                                  
                <div style={[styles.arrowButton, {color: this.props.light ? 'white' 
                : (this.props.red ? 'red' : 'black')}]} 
                onMouseEnter={() => this.setState({hovered: true})}
                onMouseLeave={() => this.setState({hovered: false})}
                onClick={function() {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }                    
                }.bind(this)}>
                    <div style={[{
                        display: 'inline-block',
                        paddingBottom: '0rem',
                        borderBottomColor: this.state.hovered
                        ? 'transparent'
                        : (this.props.light ? '#fff' 
                        : (this.props.red ? '#f00' : '#000')),
                        borderBottomWidth: '0.065rem',
                        borderBottomStyle: 'solid',  
                        transition: 'border-bottom-color 0.15s ease',
                    }, this.props.style]}
                    className='mb-2'>      
                        {this.props.caption}
                    </div>
                    {this.props.variant !== 'none' &&
                        <div style={{
                            display: 'inline-block',
                            paddingLeft: this.state.hovered ? this.paddingLeftHover : this.paddingLeftStandard, 
                            transition: 'padding-left 0.5s ease',                         
                        }}/>
                    }
                </div>
            </>
        );
    }
}

ArrowButton = Radium(ArrowButton);

var styles = { 
    'a': {
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'none',
        }
    } ,
    arrowButton: {
        display: 'inline-block', 
        userSelect: 'none',         
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'none',
        }     
    },    
}