import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { MenuBar } from './MenuBar';
import { BorderWrapper } from './BorderWrapper';
import { TextMotionDiv } from './TextMotionDiv';
import SimpleButton from './SimpleButton';
import { ContactInfoBar } from './ContactInfoBar';
import { NewsList } from './NewsList';

import { fetchWPFeaturedPosts, fetchWPTexts, fetchWPVideos } from './App';

export class LandingPage extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {
            backgroundVideo: ''
        };
        this.backgroundImage = new Image();    
    }
    componentDidMount() {  
        fetchWPVideos().then(function(videos) {
            for (const video of videos) {
                if (video.title === 'Startseite Hintergrund') {
                    this.setState({
                        backgroundVideo: video.videoURL
                    });
                    this.backgroundImage.src = video.placeholderURL;
                    this.backgroundImage.onload = function() {
                        this.setState({backgroundImageLoaded: true});                        
                    }.bind(this);
                }
            }
        }.bind(this));
        //this.backgroundImage.src = 'https://verpfl.uber.space/wordpress/wp-content/uploads/2021/03/background.jpg';
        //this.setState({backgroundVideo: 'https://verpfl.uber.space/wordpress/wp-content/uploads/2021/03/background.mp4'});
        
        fetchWPTexts().then(function(texts){            
            for (const text of texts) {                                
                if (text.title === 'Startseite Überschrift') {
                    this.setState({
                        title: text.content
                    });
                } else if (text.title === 'Startseite Untertitel') {
                    this.setState({
                        subTitle: text.content
                    });
                }
            }
        }.bind(this));
        fetchWPFeaturedPosts().then(function(posts){                        
            this.setState({featuredPosts: posts});
        }.bind(this));
    }
    render() {
        return (
            <div style={{height: '100%', width: '100%',}}>
                <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>
                <Row style={{overflow: 'hidden'}} >
                    <div style={{height: '100%'}}>
                    <TextMotionDiv style={styles.parentElementToVideo} 
                    visible={this.state.backgroundImageLoaded} preset='fadeIn'>                                            
                        <video id='vid' style={styles.video} poster={this.backgroundImage.src} 
                        disablePictureInPicture loop muted autoPlay playsInline controls>
                            {this.state.backgroundVideo !== '' &&
                            <source src={this.state.backgroundVideo} type='video/mp4'></source>                        
                            }
                        </video>                                                                                
                    </TextMotionDiv>
                    </div>
                </Row>                               
                <div className='text-center d-block d-md-none' style={{
                    height: '45vh'//(document.documentElement?.clientHeight || window.innerHeight) * 0.7,
                }} onClick={() => window.scroll({top: 30.0 / 100.0 * window.innerHeight, behavior: 'smooth'})}>
                    <div style={styles.header}/>
                </div>
                <div className='text-center d-none d-md-block' style={{
                    height: '65vh'//(document.documentElement?.clientHeight || window.innerHeight) * 0.7,
                }} onClick={() => window.scroll({top: 40.0 / 100.0 * window.innerHeight, behavior: 'smooth'})}>
                    <div style={styles.header}/>
                </div>                
                <BorderWrapper style={styles.content} lgExtraPadding mdExtraPadding>
                    <Row className='pt-4 pt-lg-5 pb-md-1 pb-lg-4 mb-3 mb-md-4 mb-lg-5'>
                        <Col xs={11} lg={6} className='mt-1 mt-md-4 mt-lg-5'>
                            <TextMotionDiv
                            preset='fadeInUpwards'
                            visible={this.state.title}
                            whenScrolledIntoViewport>
                                <div style={{
                                    '@media(max-width: 767.98px)': {
                                        fontSize: '1.4rem',
                                        lineHeight: '1.9rem',
                                        paddingRight: '0rem'
                                    },
                                    '@media(min-width: 768px) and (max-width: 991.98px)': {
                                        fontSize: '2.4rem',
                                        lineHeight: '3.0rem',
                                        paddingRight: '0rem'                                   
                                    },
                                    fontWeight: 500,
                                    fontSize: '3.2rem',
                                    lineHeight: '3.7rem',
                                    paddingRight: '3rem',
                                }}>
                                    {this.state.title}
                                </div>
                            </TextMotionDiv>
                        </Col>
                        <Col xs={11} lg={6} className='mt-4 mt-md-5'>                            
                                <div style={{
                                    fontWeight: 300,                                    
                                    '@media(max-width: 767.98px)': {         
                                        fontSize: '1.1rem',
                                        lineHeight: '1.6rem',
                                        paddingLeft: '0rem'
                                    },
                                    '@media(min-width: 768px) and (max-width: 991.98px)': {
                                        fontSize: '1.25rem',
                                        lineHeight: '1.8rem',
                                        paddingLeft: '0rem'
                                    },
                                    '@media(min-width:992px)': {
                                    fontSize: '1.5rem',
                                    lineHeight: '2.0rem',
                                    paddingLeft: '3rem'
                                    }
                                }}>
                                    <TextMotionDiv
                                    preset='fadeInUpwards'
                                    visible={true}
                                    whenScrolledIntoViewport>
                                        {this.state.subTitle}
                                    </TextMotionDiv>
                                    <TextMotionDiv
                                    preset='fadeInUpwards'
                                    visible={this.state.title && this.state.subTitle}
                                    whenScrolledIntoViewport>
                                        <div className='mt-3 pt-3 mt-md-4'>
                                            <div style={{                                                
                                                '@media(max-width: 767.98px)': {
                                                    fontSize: '0.8em',
                                                    lineHeight: '1.8rem',
                                                },
                                                fontSize: '1.1rem',
                                                lineHeight: '1.8rem',
                                            }} className='d-inline-block mr-3 mb-3'>
                                                <NavLink 
                                                onClick={() => this.setState({quickLinkMapClicked: true})}
                                                exact
                                                style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    ':hover': {
                                                        color: '#000',
                                                        textDecoration: 'none',
                                                    }
                                                }}
                                                key={'quicklink_map'} to={'/map'}>
                                                    <SimpleButton active={this.state.quickLinkMapClicked} 
                                                    caption='Baum-Karte'/>
                                                </NavLink>
                                            </div>
                                            <div style={{                                                
                                                '@media(max-width: 767.98px)': {
                                                    fontSize: '0.8em',
                                                    lineHeight: '1.8rem',
                                                },
                                                fontSize: '1.1rem',
                                                lineHeight: '1.8rem',
                                            }} className='d-inline-block'>
                                                <NavLink 
                                                onClick={function() {
                                                    this.setState({quickLinkDonateClicked: true});
                                                    window.scroll({
                                                        top: 0,                                                        
                                                    });                                                    
                                                }.bind(this)}
                                                exact
                                                style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                    ':hover': {
                                                        color: '#000',
                                                        textDecoration: 'none',
                                                    }
                                                }}
                                                key={'quicklink_donate'} to={'/donate'}>
                                                    <SimpleButton 
                                                    active={this.state.quickLinkDonateClicked}
                                                    outline caption='Baum spenden'/>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </TextMotionDiv>
                                </div>                                                            
                        </Col>
                    </Row>
                </BorderWrapper>    
                {this.state.title && this.state.subTitle &&                        
                        <NewsList news={this.state.featuredPosts}/>                    
                }
                <ContactInfoBar/>
                <style jsx>
                    {`
                        ::-webkit-media-controls {
                            display: none !important;
                        }
                        ::-webkit-slider-thumb {
                            display: none !important;
                        }
                        ::-moz-range-track {
                            display: none !important;
                        }
                        ::-moz-range-thumb {
                            display: none !important;
                        }
                    `}
                </style>
            </div>
        );
    }
}

LandingPage = Radium(LandingPage);

var styles = {
    header: {     
        userSelect: 'none',
        width: '100%',              
        height: '100%',
        minHeight: '-webkit-fill-available',    
        display: 'flex',
        flex: 1,
        flexDirection: 'column',        
    },    
    parentElementToVideo: {
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: -1,
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',        
        height: '100vh'
    },
    video: {
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: -1,        
        height: '100%',
        width: '177.777vh',
        minWidth: '100%',
        minHeight: '56.25vw',
        position: 'absolute',
        left: '50%',
        top: '50%',
        '@media(max-width: 767.98px)': {
            transform: 'translate(-50%, -90%)',
        },
        transform: 'translate(-50%, -70%)',          
    },    
    content: {
        //minHeight: '100vh',
        backgroundColor: '#fff',
    },
};
