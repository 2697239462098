import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, TileLayer, ZoomControl, Marker, Popup, MapConsumer } from 'react-leaflet';
import { use100vh } from 'react-div-100vh'
import L from 'leaflet';

import { MenuBar } from './MenuBar';
import { BorderWrapper } from './BorderWrapper';
import { TextMotionDiv } from './TextMotionDiv';
import SimpleButton from './SimpleButton';
import { ContactInfoBar } from './ContactInfoBar';

import leafIMG from './assets/map_tree.png';
import leafHighlightIMG from './assets/map_tree_highlight.png';
import { BackgroundImage } from './BackgroundImage';
import { Description } from './Description';
import { fetchWPTrees } from './App';

const leafIcon = new L.Icon({
    iconUrl: leafIMG,
    iconRetinaUrl: leafIMG,
    iconAnchor: [15, 29],
    popupAnchor: [0,-29],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,    
    iconSize: new L.Point(29, 40),
});

const leafIconHighlight = new L.Icon({
    iconUrl: leafHighlightIMG,
    iconRetinaUrl: leafHighlightIMG,
    iconAnchor: [15, 29],
    popupAnchor: [0,-29],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,    
    iconSize: new L.Point(29, 40),
});

export class MapPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markerList: []
        };
        this.lastScrollY = -1;
    }
    componentDidMount() {
        fetchWPTrees().then((trees) => this.setState({markerList: trees}));    
        //window.addEventListener('scroll', this.handleScroll.bind(this), {passive: true});
    }
    /*
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(event) {                
        if (this.lastScrollY > window.pageYOffset && window.pageYOffset < 100) {
            this.closePopup();
        }
        this.lastScrollY = window.pageYOffset;
    }
    */
    closePopup() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });       
        setTimeout(() => this.setState({popupOpen: false}), 500);
    }
    openPopup(marker) {
        this.setState({
            popupOpen: true,
            activeMarker: marker,
            activeMakerPictureLoaded: false                                                
        });
        window.scroll({
            top: (document.documentElement?.clientHeight || window.innerHeight) * 0.6,
            behavior: 'smooth'
        });        
    }
    render() {
        return (
            <div style={{backgroundColor: '#fff'}}>
                <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>
                <Row style={{backgroundColor: '#fff',}}>
                    <div style={{
                        backgroundColor: '#fff',
                        transition: 'height 0.3s ease',
                        width: '100%',
                        height: '100vh'
                    }}>
                        <MapContainer
                        scrollWheelZoom={true}
                        center={[52.9222, 9.2289]}               
                        style={{                            
                            height: '100%', 
                            width: '100%', 
                            zIndex: 0}}
                        zoom={13}
                        zoomControl={false}
                        whenCreated={(map) => this.map = map}>
                            <TileLayer                            
                            style={{
                                width: '100%', 
                                height: this.state.popupOpen ? '50vh' : '100vh'
                            }}
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />                            
                            <ZoomControl position={'bottomright'}/>
                            {this.state.markerList.map((marker) =>
                                <Marker position={marker.coordinates}
                                icon={this.state.popupOpen && marker 
                                    && marker.id === this.state.activeMarker.id 
                                    ? leafIconHighlight : leafIcon}>
                                    <Popup onOpen={() => this.openPopup(marker)}
                                    onClose={function() {
                                        this.setState({
                                            popupOpen: false
                                        });                                        
                                    }.bind(this)}>
                                    </Popup>
                                </Marker>
                            )}
                        </MapContainer>                    
                    </div>                    
                </Row>                             
                <div style={{     
                    backgroundColor:  '#fff',
                    transition: 'height 0.3s ease',
                    width: '100%',                                                                                                                     
                    minHeight: this.state.popupOpen ? '50vh' : '0vh',                    
                }}>
                    {this.state.popupOpen && 
                    <Row style={{backgroundColor: '#fff'}}>
                        <Col xs={12} md={6} style={{backgroundColor: '#fff'}}>
                            <Row style={{                                
                                backgroundColor: '#fff',
                            }}>
                                <div style={{width: '100%', height: '100%', backroundColor: '#fff'}}>
                                    <TextMotionDiv visible={this.state.popupOpen}
                                    preset='fadeIn'
                                    whenScrolledIntoViewport>
                                        {this.state.activeMarker &&
                                        <div style={{       
                                            backgroundColor: this.state.activeMakerPictureLoaded 
                                            ? 'transparent' 
                                            : '#eee', 
                                            '@media(max-width: 767.98px)': {
                                                height: '30vh',                                                
                                            },    
                                            '@media(min-width: 768px) and (max-width: 991.98px)': {
                                                height: '60vh'
                                            },
                                            '@media(min-width: 992px)': {
                                                height: '70vh'
                                            }                                    
                                        }}>                                               
                                            <BackgroundImage 
                                            onLoad={() => this.setState({activeMakerPictureLoaded: true})}
                                            pictureURL={this.state.activeMarker.pictureUrl}/>                                            
                                        </div>
                                        }                                
                                    </TextMotionDiv>                                            
                                </div>
                            </Row>                                                
                        </Col>
                        <Col xs={12} md={6} style={{backgroundColor: '#fff', height: '100%'}}>
                            <BorderWrapper
                            style={{
                                backgroundColor: '#fff', 
                                minHeight: '50vh',
                                '@media(max-width: 767.98px)': {
                                    minHeight: '100vh'
                                },
                            }}>                        
                                <div style={{    
                                    backgroundColor: '#fff',                                
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start'
                                }}>                                    
                                    <div className='mt-2 text-left'
                                    style={{                                        
                                        fontSize: '0.9rem',
                                        lineHeight: '1.5rem',
                                        textDecoration: 'underline',
                                        userSelect: 'none',
                                        cursor: 'pointer'
                                    }} onClick={this.closePopup.bind(this)}>
                                        Schließen
                                    </div>
                                    <div style={{
                                        '@media(max-width: 767.98px)': {
                                            fontSize: '1.4rem',
                                            lineHeight: '1.9rem',                                        
                                        },
                                        '@media(min-width: 768px) and (max-width: 991.98px)': {
                                            fontSize: '2.4rem',
                                            lineHeight: '2.6rem',                                        
                                        },
                                        fontWeight: 500,
                                        fontSize: '3.2rem',
                                        lineHeight: '3.7rem',                                    
                                    }} className='mt-2'>
                                        {this.state.activeMarker.title}
                                    </div>                                                                        
                                    <Description label={'Ort'} value={this.state.activeMarker.location}/>
                                    <Description label={'Beschreibung'} value={this.state.activeMarker.description}/>
                                    <Description label={'Code'} value={this.state.activeMarker.code}/>
                                </div>
                            </BorderWrapper>                                
                        </Col>
                    </Row>
                    }   
                                        
                </div>                                              
                <style jsx>
                    {`
                    .leaflet-popup-content-wrapper {
                        visibility: hidden;                        
                    }
                    .leaflet-popup-content-wrapper .leaflet-popup-content {                        
                        visibility: hidden;
                    }
                    
                    .leaflet-popup-tip-container {
                        visibility: hidden;
                    }
                    `}
                </style>
            </div>
        );
    }
}

MapPage = Radium(MapPage);

var styles = {
    
};
