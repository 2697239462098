import React from 'react';
import Radium from 'radium';
import { Spinner } from 'react-bootstrap';

export default class SimpleButton extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            active: false
        };
    }
    onClick() {        
        this.setState({
            active: true
        });
        setTimeout(() => this.setState({active: false}), 2000);
        if (this.props.onClick) {            
            this.props.onClick();
        }        
    }
    render() {
        return (
            <div onClick={this.onClick.bind(this)} style={[styles.simpleButton, this.props.outline ? styles.outline : styles.filled]}>   
                {this.props.caption}
                <div>
                    {!this.state.active &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" 
                    fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>     
                    }
                    {(this.state.active || this.props.active) &&
                    <Spinner className='ml-1' animation="border" role="status" size='sm'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    }
                </div>
            </div>
        );
    }
}

SimpleButton = Radium(SimpleButton);

var styles = {
    simpleButton: {    
        display: 'inline-block',        
        transition: 'color 0.3s ease, background-color 0.3s ease',
        '@media(max-width: 767.98px)': {         
            paddingLeft: '1rem',
            paddingRight: '4rem',
            paddingTop: '1.0rem',
            paddingBottom: '1.0rem',
        },
        paddingLeft: '1.5rem',
        paddingRight: '3.5rem',
        paddingTop: '1.0rem',
        paddingBottom: '1.0rem',                
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        ':hover': {
            transform: 'translate(-0.2rem, -0.2rem)',
            boxShadow: '5px 10px #9BA65733'
        }
    },
    filled: {
        backgroundColor: '#9BA657',
        color: '#fff',
    },
    outline: {
        border: '1px solid #9BA657',
        color: '#9BA657'

    }
};