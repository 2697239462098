import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from 'react-router-dom';
import axios from 'axios';

import { TextMotionDiv } from './TextMotionDiv';
import logoIMG from './assets/leaf_white.svg';
import menuIMG from './assets/menu_white.svg';
import { fetchWPPages } from './App';

export class PopupMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            menuData: [],    
            menuItemsFadeInCounter: 0            
        };
    }
    componentDidMount() {
        fetchWPPages().then(function(pages){
            let menuData = [
                {
                    title: 'Seiten',                
                },
                {
                    title: 'Startseite',
                    destination: '/',                    
                },
                {
                    title: 'Baum spenden',
                    destination: '/donate'
                },
                {
                    title: 'Baum-Karte',
                    destination: '/map'
                },                
                {
                    title: 'Beiträge',
                    destination: '/articles'
                },                
            ];
            console.log(pages);
            for (const page of pages) {            
                menuData.push({
                    title: page.title,
                    destination: '/page/' + page.id 
                })
            }
            menuData.push(...[
                {
                    title: 'Kontakt'
                },
                {
                    title: 'Schreiben Sie uns',
                    href: 'mailto:info@verden-pflanzt.de'
                },
                {
                    title: 'Rufen Sie uns an',
                    href: 'tel:+4916094773451'
                }
            ]);
            this.setState({menuData: menuData});
        }.bind(this));
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showMenu !== this.props.showMenu && this.props.showMenu === true) {
            this.setState({
                menuItemsFadeInCounter: 0,
                menuData: this.state.menuData.map(function(entry) {
                    entry.visible = false;
                    return entry;
                })
            });
            setTimeout(this.countUpMenuItemsFadeIn.bind(this), 100);
        }
    }
    countUpMenuItemsFadeIn() {
        if (this.state.menuItemsFadeInCounter < this.state.menuData.length) {
            let menuData = this.state.menuData;
            menuData[this.state.menuItemsFadeInCounter].visible = true;
            this.setState({
                menuItemsFadeInCounter: this.state.menuItemsFadeInCounter + 1,
                menuData: menuData
            });
            setTimeout(this.countUpMenuItemsFadeIn.bind(this), 50);
        }
    }
    menuButtonHover(index) {
        let menuData = this.state.menuData;        
        menuData[index].hovered = true;
        this.setState({
            menuData: menuData
        });
    }
    menuButtonUnhover(index) {
        let menuData = this.state.menuData;        
        menuData[index].hovered = false;
        this.setState({
            menuData: menuData
        });
    }
    render() {        
        return (
            <>
            <div className='fixed-top' style={[styles.menu, 
                {  
                    transition: 'transform 0.4s ease, opacity 0.4s ease',                    
                    opacity: this.props.showMenu ? 1.0 : 0.0,
                    pointerEvents: this.props.showMenu ? 'auto' : 'none'
                }]}>
                <Container fluid>
                    <Row>                 
                        <Col className='pt-3 pb-3 pb-md-4 fixed-top'>                                        
                            <div style={{
                                display: 'flex', 
                                flex: '1', 
                                flexDirection: 'row',                             
                                '@media(max-width: 767.98px)': {         
                                    height: '1.2rem',
                                },
                                height: '1.5rem',
                            }}>                    
                                <div style={styles.logo} className='ml-md-2'
                                onClick={() => this.props.togglePopupMenu()}>
                                    <img src={logoIMG} style={styles.logoImage} className='mr-2 mr-md-3 mt-md-1'/>
                                    VERDEN PFLANZT e.V.
                                </div>
                                <div style={styles.menuButton} className='text-right'
                                onClick={() => this.props.togglePopupMenu()}>
                                    <span className='d-none d-lg-inline-block mr-md-4'>
                                        Menü
                                    </span> 
                                    <span className='mr-1 mr-sm-2'>
                                        <img src={menuIMG} style={styles.menuImage}/>
                                    </span>
                                </div>
                            </div>                          
                        </Col>               
                    </Row>     
                    <Row>
                        <Col className='pt-4 pt-md-5 mt-5 pl-5'>                            
                            {this.state.menuData.map(function(item, index) {
                                if (item.destination) {
                                    return(
                                        <NavLink onClick={function() {
                                                window.scrollTo(0, 0);
                                                this.props.togglePopupMenu();                  
                                            }.bind(this)} exact
                                            key={'menuLink' + index} to={item.destination}
                                            style={styles.menuNavLink}>  
                                            <div key={'menuLinkDiv' + index} className='mb-2 mb-md-3' 
                                            onMouseEnter={() => this.menuButtonHover(index)}
                                            onMouseLeave={() => this.menuButtonUnhover(index)}
                                            style={[                                    
                                                styles.menuNavLinkDiv,
                                                {                                           
                                                    transition: 'opacity 1.0s ease, transform 0.3s ease',
                                                    transform: this.props.showMenu && item.visible 
                                                    ? 'translateX(calc(0rem + ' + (item.hovered ? '-0.3rem' : '0.0rem') + '))' 
                                                    : 'translateX(calc(1.5rem + ' + (item.hovered ? '-0.3rem' : '0.0rem') + '))' ,
                                                    opacity: this.props.showMenu 
                                                    && item.visible ? 1.0 : 0.0,
                                                }
                                            ]}>
                                                {item.title}                                    
                                            </div>                                      
                                        </NavLink>
                                    );
                                } else if (item.href) {
                                    return (                                        
                                        <div className='d-flex'
                                        style={{
                                            justifyContent: 'flex-end', 
                                        }}>
                                            <a 
                                            key={'contactLink' + index}
                                            onMouseEnter={() => this.menuButtonHover(index)}
                                            onMouseLeave={() => this.menuButtonUnhover(index)}
                                            style={[                                    
                                                styles.contactLink,
                                                {                                                                                                                                           
                                                    transition: 'opacity 1.0s ease, transform 0.3s ease',
                                                    transform: this.props.showMenu && item.visible 
                                                    ? 'translateX(calc(0rem + ' + (item.hovered ? '-0.3rem' : '0.0rem') + '))' 
                                                    : 'translateX(calc(1.5rem + ' + (item.hovered ? '-0.3rem' : '0.0rem') + '))' ,
                                                    opacity: this.props.showMenu 
                                                    && item.visible ? 1.0 : 0.0,
                                                }
                                            ]} 
                                            href={item.href} className='mb-2 mb-md-3' >
                                                {item.title}
                                            </a> 
                                        </div>                                                                               
                                    );
                                } else {
                                    return (
                                        <div key={'menuDivider' + index} className={
                                            'mb-2 mb-md-3 ' + (index > 0 && 'mt-3 mt-md-3') 
                                        }
                                        style={[                                    
                                            styles.dividerLabel,
                                            {                                           
                                                transition: 'opacity 1.0s ease, margin-right 0.3s ease',
                                                marginRight: this.props.showMenu 
                                                && item.visible ? '0rem' : '-1.5rem',
                                                opacity: this.props.showMenu 
                                                && item.visible ? 1.0 : 0.0,
                                            }
                                        ]}>
                                            {item.title}                                    
                                        </div>
                                    );
                                }
                            }.bind(this))} 
                        </Col>
                    </Row>
                </Container>     
            </div>
            </>
        );
    }
}

PopupMenu = Radium(PopupMenu);

var styles = {      
    menu: {
        width: '100%', 
        height: '100%', 
        backgroundColor: '#9BA657', //010101        
        color: '#fff' ,
        zIndex: 2
    },
    logo: {    
        fontFamily: '"Playfair Display", serif',
        flex: '5',        
        justifyContent: 'flex-start',
        display: 'flex',        
        justifyContent: 'left',  
        fontWeight: 400, 
        userSelect: 'none',               
        '@media(max-width: 767.98px)': {
            marginTop: '-0.4rem',
            fontSize: '1.4rem',
            letterSpacing: '0.03rem',
        },    
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '-0.4rem',
            fontSize: '1.9rem',
            letterSpacing: '0.05rem',
        },
        '@media(min-width: 992px)': {
            marginTop: '-0.8rem',
            fontSize: '2.3rem',
            letterSpacing: '0.05rem',
        }
    },
    logoImage: {
        '@media(max-width: 767.98px)': {
            height: '1.8rem',
            paddingTop: '0.2rem'
        },
        height: '2.5rem',
        paddingTop: '0.4rem'
    },
    menuButton: {
        fontFamily: '"Playfair Display", serif',
        fontWeight: 400,     
        flex: '1', 
        display: 'flex', 
        justifyContent: 'flex-end',
        lineHeight: '0.3rem',
        userSelect: 'none',
        cursor: 'pointer',
        /*
        '@media(max-width: 767.98px)': {
            marginTop: '0.3rem',
            fontSize: '1.0rem',        
        },
        */    
        '@media(min-width: 768px)': {
            marginTop: '0.8rem',
            fontSize: '1.8rem',
        }
    },
    menuImage: {
        '@media(max-width: 575.98px)': {
            height: '1.0rem',
            marginTop: '0.1rem'
        },
        '@media(min-width: 576px) and (max-width: 767.98px)': {
            height: '1.1rem',
            marginTop: '0.2rem'
        },
        '@media(min-width: 768px)': {
            height: '1.3rem',
            marginTop: '-0.3rem'
        }
    },
    menuNavLink: {
        display: 'flex',
        flex: 1,
        flex: 'column',
        color: '#fff',
        fontWeight: 500,
        textDecoration: 'none', 
        justifyContent: 'flex-end',        
    },
    menuNavLinkDiv: {
        display: 'flex',        
        '@media(max-width: 767.98px)': {
            fontSize: '1.4rem',
            lineHeight: '1.9rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '2.4rem',
            lineHeight: '2.6rem',            
        },
        fontWeight: 300,
        fontSize: '2.5rem',
        lineHeight: '2.8rem',        
    },
    dividerLabel: {
        textTransform: 'uppercase',
        display: 'flex',        
        justifyContent: 'flex-end',
        userSelect: 'none',
        '@media(max-width: 767.98px)': {
            fontSize: '0.8rem',
            lineHeight: '1.1rem',
            letterSpacing: '0.04rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '0.85rem',
            lineHeight: '1.15rem',   
            letterSpacing: '0.045rem',
        },
        fontWeight: 300,
        fontSize: '0.9rem',
        lineHeight: '1.2rem',
        letterSpacing: '0.05rem',
    },
    contactLink: {
        display: 'inline-block',    
        justifyContent: 'flex-end', 
        color: '#fff',  
        userSelect: 'none',
        '@media(max-width: 767.98px)': {
            fontSize: '1.2rem',
            lineHeight: '1.9rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.5rem',
            lineHeight: '1.7rem',            
        },
        fontWeight: 300,
        fontSize: '1.8rem',
        lineHeight: '2.0rem', 
        ':hover': {
            textDecoration: 'none',
        }       
    },
};

