import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import { TextMotionDiv } from './TextMotionDiv';

export class NewsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            backgroundImageLoaded: false,
            hovered: false                 
        };        
        this.backgroundImage = new Image();
    }   
    componentDidMount() {    
        this.loadBackgroundImage();
    }
    componentDidUpdate(prevProps, prevState) {                
        if (prevProps.pictureURL !== this.props.pictureURL) {
            this.loadBackgroundImage();
        }
    }
    loadBackgroundImage() {            
        if (this.props.pictureURL) {            
            this.backgroundImage.src = this.props.pictureURL;
            this.backgroundImage.onload = function() {
                this.setState({backgroundImageLoaded: true});        
                if (this.props.onLoad !== undefined) this.props.onLoad();                
            }.bind(this);
        }
    }
    render() {
        return (            
            <Row>            
            <TextMotionDiv             
            onMouseEnter={() => this.setState({hovered: true})}
            onMouseLeave={() => this.setState({hovered: false})}            
            visible={true} preset='fadeInUpwardsStrong' whenScrolledIntoViewport
            addToTranslateY={this.state.hovered ? '-0.4rem' : '0.0rem'}
            cssTransitions={'box-shadow 0.2s ease'}
            transitionTime={this.state.hovered ? '0.2s' : '0.6s'}
            style={[
                styles.card,
                {                    
                    boxShadow: this.state.hovered 
                    ? '0.1rem 0.8rem 1.0rem 1.0rem #eee'
                    : '0.1rem 0.8rem 1.0rem 0.5rem #eee',                                    
                }
            ]}        
            className='p-3 m-3'>
                <div
                //onMouseEnter={() => this.setState({hovered: true})}
                //onMouseLeave={() => this.setState({hovered: false})}
                //onClick={() => this.setState({showDetails: true})}
                >
                    <div style={styles.content} className=''>                                
                        <div className=''
                        style={{                                                                                    
                            backgroundColor: '#f9f9f9',
                            backgroundPosition: '50% 50%',                             
                            backgroundSize: '50% 50%',
                            backgroundRepeat: 'no-repeat',                            
                        }}>
                            <TextMotionDiv visible={this.state.backgroundImageLoaded || !this.props.pictureURL} 
                            preset='fadeIn'>
                                <div 
                                style={{                                                             
                                    backgroundImage: 'url(' + this.backgroundImage.src + ')',
                                    backgroundPosition: '50% 50%',                                     
                                    backgroundSize: this.props.contain === true ? 'contain' : 'cover',
                                    backgroundRepeat: 'no-repeat',                                    
                                    '@media (max-width: 991.98px)': {
                                        height: '60vh',
                                    },      
                                    height: '80vh',
                                    display: 'flex', 
                                    flex: '1', 
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    borderRadius: '0.1rem'
                                }}>     
                                    <div style={[
                                        styles.textContent, 
                                        //this.props.bright ? styles.darkText : styles.brightText,
                                        {
                                            //transform: this.state.hovered ?  'translateY(-0.3rem)' : '',
                                        },
                                    ]} className='pl-4 pr-5 py-4 px-md-5 py-md-5'>
                                        <div style={[
                                            styles.subTitle,
                                            {
                                                color: (this.props.bright === undefined || this.props.bright === true) ? '#fff' : '#000',
                                            }
                                            ]} className='mt-2 mt-md-4'>
                                            <TextMotionDiv visible={this.props.subTitle} 
                                                preset={'fadeIn'} 
                                                whenScrolledIntoViewport>                                    
                                                {this.props.subTitle}
                                            </TextMotionDiv>
                                        </div>                                                                              
                                        <TextMotionDiv visible={this.props.title} 
                                        preset={'fadeIn'} 
                                        whenScrolledIntoViewport>             
                                            <div style={[
                                            styles.title,                                        
                                            {  
                                                 
                                                color: (this.props.bright === undefined || this.props.bright === true) ? '#fff' : '#000',
                                                /*
                                                textDecorationColor: this.state.hovered
                                                ? 'transparent'
                                                : '#fff',
                                                */
                                            }                                   
                                            ]} className='mt-1 mt-md-2'>                                                
                                                {this.props.title}                                                
                                            </div>                   
                                        </TextMotionDiv>                                        
                                    </div>
                                </div>  
                            </TextMotionDiv>
                        </div>                        
                    </div>                    
                </div>
            </TextMotionDiv>                        
            </Row>
        );
    }
}

NewsCard = Radium(NewsCard);

var styles = {
    card: {        
        color: '#000',         
        backgroundColor: '#fff',   
        width: '100%',
        height: '100%',
        borderRadius: '0.5rem',        
    },
    content: {        
    },
    textContent: {
        transition: 'transform 0.4s ease',
    },    
    categoryLink: {                            
        
    }, 
    subTitle: {        
        '@media(max-width: 767.98px)': {         
            fontSize: '0.8em',
            lineHeight: '1.8rem',
        },        
        fontSize: '1.0rem',
        lineHeight: '1.8rem',
        textShadow: '#000 -0.01rem -0.01rem 0.2rem'
    },
    title: {
        '@media(max-width: 767.98px)': {         
            fontSize: '1.1rem',
            lineHeight: '1.6rem',                   
            textUnderlineOffset: '0.1rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.25rem',
            lineHeight: '1.8rem',
            textUnderlineOffset: '0.15rem',
        },
        textUnderlineOffset: '0.2rem',
        display: 'inline-block',
        fontSize: '1.5rem',
        lineHeight: '2.0rem',      
        textDecorationLine: 'underline',        
        transition: 'text-decoration-color 0.3s ease',  
        textShadow: '#000 -0.01rem -0.01rem 0.2rem'
    },
    teaser: {     
        '@media(max-width: 767.98px)': {
            fontSize: '1.0rem',  
            lineHeight: '1.3',      
        },
        fontSize: '1.2rem',
        lineHeight: '1.5rem',         
    },
}