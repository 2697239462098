import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import Div100vh from 'react-div-100vh';

import { MenuBar } from './MenuBar';
import { BorderWrapper } from './BorderWrapper';
import { TextMotionDiv } from './TextMotionDiv';
import { fetchWPUnfeaturedPosts } from './App';

import { ContactInfoBar } from './ContactInfoBar';
import { NewsList } from './NewsList';

export class NewsOverviewPage extends React.Component {
    constructor(props) {
        super(props);        
    }
    componentDidMount() {                        
        fetchWPUnfeaturedPosts().then(function(posts){                        
            this.setState({posts: posts});
        }.bind(this));
    }
    render() {
        return (
            <div style={{height: '100%', width: '100%',}}>
                <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>
                <Row className='mt-5 pt-2 mt-md-5 pt-md-5' style={{
                    backgroundColor: '#fff',
                }}/>                
                <NewsList news={this.state.posts} stretchWhileEmpty/>                
                <ContactInfoBar/>
            </div>
        );
    }
}

NewsOverviewPage = Radium(NewsOverviewPage);

var styles = {
    header: {     
        backgroundColor: '#fff',        
    },
};
