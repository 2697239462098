import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from 'react-router-dom';
import { NewsCard } from './NewsCard';
import { Spinner } from 'react-bootstrap';

export class NewsList extends React.Component {
    constructor(props) {
        super(props);         
        this.state = {
            news: this.props.news ? this.props.news : []
        };        
    }    
    componentDidUpdate(prevProps, prevState) {        
        if (this.props.news         
            && (!prevProps.news || prevProps.news.length !== this.props.news.length)) {
            this.setState({
                news: this.props.news            
            });             
            if (this.props.onListPopulated) {
                this.props.onListPopulated();
            }                       
        }
    }
    render() {        
        return (                        
            <Row style={{
                backgroundColor: '#fff', 
                minHeight: this.props.stretchWhileEmpty ? '100vh' : ''
            }}>
                {(!this.state.news || this.state.news.length === 0) &&
                    <div className='text-center pb-3' style={{width: '100%'}}>
                        <Spinner animation="border" role="status" size='sm'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }                
                {this.state.news && this.state.news.map((entry, index) =>
                <Col xs={12} lg={6} xl={4} className='mb-3 mb-md-5'>
                    <NavLink onClick={function() {
                        window.scrollTo(0, 0);                                              
                    }.bind(this)} exact
                    key={'newsLink' + index} to={{
                        pathname: '/news/' + entry.id
                    }}
                    style={styles.recipeLink}>                            
                        <NewsCard                         
                        pictureURL={entry.pictureURL}
                        subTitle={entry.subTitle}
                        picture={entry.picture}
                        title={entry.title}
                        contain={entry.contain}
                        bright={entry.bright}
                        />                            
                    </NavLink>                    
                </Col> 
                )}
            </Row>                                             
        );
    }
}

NewsList = Radium(NewsList);

var styles = {    
    recipeLink: {
        color: '#000',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'none',
        },
    }
};

