import React from 'react';
import Radium from 'radium';
import { InView } from 'react-intersection-observer';

export class TextMotionDiv extends React.Component {
    constructor(props) {
        super(props);     
        this.state = {
            scrolledIntoViewport: false,
            isUnmounting: false,
            visible: false,
            transitionTime: '1.0s',
        };        
    }   
    componentWillUnmount() {
        this.setState({isUnmounting: true});        
    } 

    componentDidUpdate(prevProps, prevState) {
        if (this.props.visible !== this.state.visible 
            || this.props.transitionTime !== this.state.transitionTime) {
            this.setState({
                visible: this.props.visible,
                transitionTime: this.props.transitionTime
            });    
        }        
    }
    componentDidMount() {
        this.setState({visible: this.props.visible});        
    }
    render() {
        let shouldDisplay = this.state.visible 
        && (this.props.whenScrolledIntoViewport !== true || this.state.scrolledIntoViewport);        
        let animateOpacity = 1.0, initialOpacity = 1.0, animateTranslateY = '0rem',
        initialTranslateY = '0rem', exitOpacity = 1.0, exitTranslateY = '0rem';
        const addToTranslateY = this.props.addToTranslateY ? this.props.addToTranslateY : '0.0rem'        
        const transitionTime = this.props.transitionTime ? this.props.transitionTime : '0.6s';
        //const transitionTime = '0.2s';
        if (this.props.animate !== undefined) {
            if (this.props.animate.opacity !== undefined) {
                animateOpacity = this.props.animate.opacity;
            }
            if (this.props.animate.translateY !== undefined) {
                animateTranslateY = this.props.animate.translateY;
            }
        }        
        if (this.props.initial !== undefined) {
            if (this.props.initial.opacity !== undefined) {
                initialOpacity = this.props.initial.opacity;
            }
            if (this.props.initial.translateY !== undefined) {
                initialTranslateY = this.props.initial.translateY;
            }
        } 
        if (this.props.preset === 'fadeInUpwards') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '1.0rem';
            animateTranslateY = '0.00rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';        
        } else if (this.props.preset === 'fadeInUpwardsStrong') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '3.0rem';
            animateTranslateY = '0.00rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';        
        } else if (this.props.preset === 'fadeInDownwards') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '-2rem';
            animateTranslateY = '0rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';
        } else if (this.props.preset === 'fadeIn') {
            initialOpacity = 0.0; 
            animateOpacity = 1.0;
            initialTranslateY = '0rem';
            animateTranslateY = '0rem';
            exitOpacity = 0.0;
            exitTranslateY = '0rem';
        }    
        return (
            <>     
                <InView>{
                    function({inView, ref, entry}) {
                        if (inView && this.state.scrolledIntoViewport !== true) {                            
                            setTimeout(() => this.setState({scrolledIntoViewport: true}), 
                            this.props.delay ? this.props.delay : 0);
                        }
                        return <div ref={ref}></div>;
                    }.bind(this)
                }</InView>           
                <div onClick={this.props.onClick} 
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                style={[{
                    transition: 
                    (this.props.cssTransitions ? this.props.cssTransitions + ',' : '') + 
                    'transform ' + transitionTime + ' ease, opacity ' + transitionTime +' ease', // 0.6s
                    opacity: this.state.isUnmounting ? exitOpacity 
                    : shouldDisplay ? animateOpacity : initialOpacity,
                    transform: this.state.isUnmounting 
                    ? 'translateY(calc(' + (exitTranslateY + addToTranslateY) + '))'
                    : shouldDisplay 
                    ? 'translateY(calc(' + animateTranslateY + ' + ' + addToTranslateY  + '))' 
                    : 'translateY(calc(' + initialTranslateY + ' + ' + addToTranslateY + '))',
                    //maxHeight: '0vh',
                    //overflowY: 'hidden'
                }, this.props.style]} className={this.props.className}>
                    {this.props.children}
                </div>
            </>
        );
    }
}

TextMotionDiv = Radium(TextMotionDiv);

var styles = {
    root: {
        display: 'flex',
        flex: 1
    }
};