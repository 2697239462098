import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';

import { TextMotionDiv } from './TextMotionDiv';

export class IconInfoBox extends React.Component {
    constructor(props) {
        super(props);        
    }
    render() {
        return (
            <TextMotionDiv visible={this.props.iconStyle} preset='fadeInUpwards' whenScrolledIntoViewport>
                <Row>
                    <Col xs={2} md={1} lg={12}>
                        <div style={styles.icon}>
                            {this.props.iconStyle === 'none' &&
                            <svg fill='white' 
                            width='1.7em'
                            height='1.7em'
                            class='bi bi-tree-fill'
                            viewBox='0 0 16 16'>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>}
                            {this.props.iconStyle === 'tree' &&
                            <svg fill='white' 
                            width='1.7em'
                            height='1.7em'
                            class='bi bi-tree-fill'
                            viewBox='0 0 16 16'>
                                <path d="M8.416.223a.5.5 0 0 0-.832 0l-3 4.5A.5.5 0 0 0 5 5.5h.098L3.076 8.735A.5.5 0 0 0 3.5 9.5h.191l-1.638 3.276a.5.5 0 0 0 .447.724H7V16h2v-2.5h4.5a.5.5 0 0 0 .447-.724L12.31 9.5h.191a.5.5 0 0 0 .424-.765L10.902 5.5H11a.5.5 0 0 0 .416-.777l-3-4.5z"/>
                            </svg>}
                            {this.props.iconStyle === 'location' &&
                            <svg fill='white' 
                            width='1.7em'
                            height='1.7em'
                            class='bi bi-tree-fill'
                            viewBox='0 0 16 16'>
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                          </svg>}
                          {this.props.iconStyle === 'tree-document' &&
                          <svg fill='white' 
                          width='1.7em'
                          height='1.7em'
                          class='bi bi-tree-fill'
                          viewBox='0 0 16 16'>
                                <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                            </svg>}
                            {this.props.iconStyle === 'donate' &&
                            <svg fill='white' 
                            width='1.7em'
                            height='1.7em'
                            class='bi bi-tree-fill'
                            viewBox='0 0 16 16'>
                                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"/>
                            </svg>}
                            {this.props.iconStyle === 'receipt' &&
                            <svg fill='white' 
                            width='1.7em'
                            height='1.7em'
                            class='bi bi-tree-fill'
                            viewBox='0 0 16 16'>
                                <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                            </svg>}
                        </div>
                    </Col>
                    <Col xs={10} md={11} lg={12}>
                        <div className='mt-1 mt-md-0 ml-2 ml-md-4 ml-lg-0 mt-lg-4' style={styles.title}>
                            {this.props.title}
                        </div>
                        <div className='mt-2 ml-2 ml-md-4 ml-lg-0 mt-lg-2' style={styles.description}>
                            {this.props.description}
                        </div>
                    </Col>
                </Row>
            </TextMotionDiv>
        );
    }
}

IconInfoBox = Radium(IconInfoBox);

var styles = {
    content: {
        backgroundColor: '#fff',             
    },
    icon: {
        display: 'inline-block',
        padding: '0.7rem',
        borderRadius: '1rem',
        backgroundColor: '#9BA657'
    },
    title: {
        fontWeight: 500,                                    
        '@media(max-width: 767.98px)': {         
            fontSize: '1.1rem',
            lineHeight: '1.6rem',                            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.25rem',
            lineHeight: '1.8rem',                            
        },
        '@media(min-width:992px)': {
        fontSize: '1.5rem',
        lineHeight: '2.0rem',                        
        }
    },
    description: {
        fontWeight: 300,                                    
        '@media(max-width: 767.98px)': {         
            fontSize: '1.1rem',
            lineHeight: '1.6rem',                            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.25rem',
            lineHeight: '1.8rem',                            
        },
        '@media(min-width:992px)': {
        fontSize: '1.5rem',
        lineHeight: '2.0rem',                        
        }
    }
};
