import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { TextMotionDiv } from './TextMotionDiv';
import { fetchWPImages } from './App';

import logoIMG from './assets/leaf.svg';
import menuIMG from './assets/menu.svg';

export class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.logoImage = new Image();
    }
    componentDidMount() {
        fetchWPImages().then(function(images){            
            for (const image of images) {
                if (image.title === 'Seitenlogo') {
                    console.log(image.pictureURL);
                    this.setState({
                        pictureURL: image.pictureURL
                    }, () => this.loadBackgroundImage());
                }
            }
        }.bind(this));        
    }    
    loadBackgroundImage() {            
        if (this.state.pictureURL) {            
            this.logoImage.src = this.state.pictureURL;
            this.logoImage.onload = function() {
                this.setState({logoImageLoaded: true});                
            }.bind(this);
        }
    }
    render() {        
        return (            
            <Row>                 
                <Col className={'pt-3 pb-3 pb-md-4 ' + (!this.props.makeContent && 'fixed-top ')} 
                style={styles.menu}>                
                    <TextMotionDiv 
                    visible={true}
                    preset='fadeIn'
                    whenScrolledIntoViewport>
                        <div style={{
                            display: 'flex', 
                            flex: '1', 
                            flexDirection: 'row', 
                            color: '#000',
                            '@media(max-width: 767.98px)': {         
                                height: '1.2rem',
                            },
                            height: '1.5rem',
                        }}>    
                            <div style={styles.logo} className='ml-md-2'>
                                <NavLink onClick={function() {
                                    window.scrollTo(0, 0);                                              
                                }.bind(this)} exact
                                style={{
                                    color: '#000',
                                    textDecoration: 'none',
                                    ':hover': {
                                        color: '#000',
                                        textDecoration: 'none',
                                    }
                                }}
                                key={'logo'} to={'/'}>
                                    <TextMotionDiv
                                    className='d-inline'
                                     visible={this.state.logoImageLoaded} 
                                    preset='fadeIn' whenScrolledIntoViewport>
                                        <img src={this.logoImage.src} style={styles.logoImage}
                                        className='mr-2 mr-md-3 mt-n2 mt-md-n3'/>
                                        VERDEN PFLANZT e.V.
                                    </TextMotionDiv>                                                                        
                                </NavLink>                
                            </div>
                            <div style={styles.menuButton} className='text-right'
                            onClick={() => this.props.togglePopupMenu()}>
                                <span className='d-none d-lg-inline-block mr-md-4'>
                                    Menü
                                </span> 
                                <span className='mr-1 mr-sm-2'>
                                    <img src={menuIMG} style={styles.menuImage}/>
                                </span>
                            </div>
                        </div>  
                    </TextMotionDiv>                  
                </Col>               
            </Row>                          
        
        );
    }
}

MenuBar = Radium(MenuBar);

var styles = {    
    menu: {                
        backgroundColor: '#fff',
        zIndex: 1,  
        fontFamily: '"Playfair Display", serif',
    },
    logo: {    
        flex: 7,        
        justifyContent: 'flex-start',
        display: 'flex',        
        justifyContent: 'left',  
        fontWeight: 400,                
        '@media(max-width: 767.98px)': {
            marginTop: '-0.4rem',
            fontSize: '1.4rem',
            letterSpacing: '0.03rem',
        },    
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '-0.4rem',
            fontSize: '1.9rem',
            letterSpacing: '0.05rem',
        },
        '@media(min-width: 992px)': {
            marginTop: '-0.8rem',
            fontSize: '2.3rem',
            letterSpacing: '0.05rem',
        }
    },
    logoImage: {
        '@media(max-width: 767.98px)': {
            height: '1.8rem',
            paddingTop: '0.2rem'
        },
        height: '2.5rem',
        paddingTop: '0.4rem'
    },
    menuButton: {   
        fontWeight: 400,     
        flex: '1', 
        display: 'flex', 
        justifyContent: 'flex-end',
        lineHeight: '0.3rem',    
        userSelect: 'none',
        cursor: 'pointer',
        /*
        '@media(max-width: 767.98px)': {
            marginTop: '0.3rem',
            fontSize: '1.0rem',        
        },
        */    
        '@media(min-width: 768px)': {
            marginTop: '0.8rem',
            fontSize: '1.8rem',
        }
    },
    menuImage: {
        '@media(max-width: 575.98px)': {
            height: '1.0rem',
            marginTop: '0.1rem'
        },
        '@media(min-width: 576px) and (max-width: 767.98px)': {
            height: '1.1rem',
            marginTop: '0.1rem'
        },
        '@media(min-width: 768px)': {
            height: '1.3rem',
            marginTop: '-0.3rem'
        }
    },
    navLink: {        
        display: 'flex',
        flex: 1,
        flex: 'column',
        color: '#000',        
        textDecoration: 'none',                
    }
};