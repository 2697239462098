import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';

export class BorderWrapper extends React.Component {
    constructor(props) {
        super(props);        
    }
    render() {
        return (
            <Row style={this.props.style} 
            className='px-sm-2 px-md-2 px-lg-4 px-xl-5'>
                <Col>
                    <Row className={                        
                        (this.props.mdExtraPadding ? 'px-md-4 ' : ' ')
                        + (this.props.lgExtraPadding ? 'px-lg-5 ' : ' ')
                        + (this.props.xlExtraPadding ? 'mx-xl-5 ' : ' ')
                    }>
                        <Col>
                            {this.props.children}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

BorderWrapper = Radium(BorderWrapper);

var styles = {
};