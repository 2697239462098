import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import { TextMotionDiv } from './TextMotionDiv';
import { ArrowButton } from './ArrowButton';

export class ContactInfoBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
        };
    }
    render() {
        return (
            <>                  
                <Row style={styles.contactInfoBar} className='px-0 px-sm-2 px-md-3 px-lg-5'>
                    <Col xs={12}>  
                        <Row className='px-0 px-sm-2 px-md-3 px-lg-5'>
                            <Col>
                                <TextMotionDiv visible={true}                                 
                                whenScrolledIntoViewport 
                                preset={'fadeInUpwards'}>
                                    <div style={styles.infoHeader}>
                                        Kontakt
                                    </div>
                                    <div style={styles.info} 
                                    dangerouslySetInnerHTML={{__html: 'Verden pflanzt e.V.<br><a style="color: #fff;" href="mailto:info@verden-pflanzt.de">info@verden-pflanzt.de</a><br>Spendenkonto:<br>Verden pflanzt – aktiv für den Umweltschutz<br>IBAN: DE29 2915 2670 0020 5657 27<br>BIC: BRLADE21VER<br>Tel.: <a style="color: #fff;" href="tel:+49 160 94773451">+49 160 94773451</a> (Jürgen Menzel)'}}></div>
                                    <div style={styles.ad}>                                                                                                                    
                                        Webdesign by <a href='https://coffeemugdev.de' target='_blank' rel='noopener noreferrer'>
                                            <ArrowButton light variant='none' caption='CoffeeMugDev'/>                  
                                        </a>                      
                                    </div>
                                </TextMotionDiv>
                            </Col>                        
                        </Row>
                    </Col>
                </Row>                
            </>
        );
    }
}

ContactInfoBar = Radium(ContactInfoBar);

var styles = {   
    contactInfoBar: { 
        backgroundColor: '#9BA657',
        color: '#fff',
        textShadow: '0.01rem 0.01rem 0.02rem #000',        
    },
    infoHeader: {
        fontWeight: 500,                       
        marginBottom: '1.5rem',        
        '@media (max-width: 991.98px)': {
            fontSize: '1.3rem',    
            marginTop: '2.2rem',
        },
        '@media (min-width: 992px)': {
            fontSize: '1.5rem',    
            marginTop: '4rem',
        },
    },
    info: {
        '@media (max-width: 991.98px)': {
            fontSize: '1.1rem',                
        },
        '@media (min-width: 992px)': {
            fontSize: '1.3rem',                
        },        
    },
    ad: {
        color: '#fff',
        textDecoration: 'none',            
        '@media (max-width: 991.98px)': {
            fontSize: '1.1rem',    
            marginBottom: '2.2rem',
            marginTop: '1.1rem'
        },
        '@media (min-width: 992px)': {
            fontSize: '1.3rem',    
            marginBottom: '4rem',
            marginTop: '1.5rem',
        },        
    }
};