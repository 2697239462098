import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import Div100vh from 'react-div-100vh';

import { MenuBar } from './MenuBar';
import { BorderWrapper } from './BorderWrapper';
import { TextMotionDiv } from './TextMotionDiv';

import { ContactInfoBar } from './ContactInfoBar';
import { BackgroundImage } from './BackgroundImage';

import picture0 from './assets/picture0.jpg';
import { fetchWPSinglePage } from './App';

export class CustomPage extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {                        
            headerImageLoaded: true
        };
        this.backgroundImage = new Image();
    }
    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            fetchWPSinglePage(this.props.match.params.id).then(function(page) {
                this.setState(page);                
                this.loadBackgroundImage();
            }.bind(this));
        }
    }
    loadBackgroundImage() {            
        if (this.state.pictureURL) {            
            this.backgroundImage.src = this.state.pictureURL;
            this.backgroundImage.onload = function() {
                this.setState({headerImageLoaded: true});        
                if (this.props.onLoad !== undefined) this.props.onLoad();                
            }.bind(this);
        }
    }
    render() {
        return (
            <div style={{height: '100%', width: '100%',}}>
                <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>
                <Row className='mt-5 pt-2 mt-md-3 pt-md-5 mt-lg-5' style={{
                    backgroundColor: '#fff',
                }}/>
                <BorderWrapper style={styles.content} mdExtraPadding lgExtraPadding>                    
                    <TextMotionDiv visible={this.state.title}
                    preset='fadeInUpwards' whenScrolledIntoViewport>
                        <div className='px-3 mt-2 mt-md-4 pt-lg-4' style={styles.title}>
                            {this.state.title}
                        </div>
                    </TextMotionDiv>                      
                    {this.state.pictureURL &&
                    <TextMotionDiv visible={this.state.headerImageLoaded}
                    preset='fadeInUpwards' whenScrolledIntoViewport>                        
                        <img src={this.backgroundImage.src} 
                        className='img-fluid'
                        style={styles.previewImage} />
                    </TextMotionDiv> 
                    }
                    <Row>
                        <Col xs={11} lg={10}>
                        {this.state.teaser &&
                        <TextMotionDiv visible={this.state.teaser}
                        preset='fadeInUpwards' whenScrolledIntoViewport>
                            <div style={styles.teaser}>
                                {this.state.teaser}
                            </div>
                        </TextMotionDiv>
                        }
                        </Col>
                        <Col xs={11} lg={10}>
                        <TextMotionDiv visible={this.state.content}
                        preset='fadeInUpwards' whenScrolledIntoViewport>
                            <div style={styles.textContent} 
                            dangerouslySetInnerHTML={{__html: this.state.content}}>                                
                            </div>
                        </TextMotionDiv>
                        </Col>
                    </Row>
                </BorderWrapper>    
                <ContactInfoBar/>
            </div>
        );
    }
}

CustomPage = Radium(CustomPage);

var styles = {
    content: {
        backgroundColor: '#fff',
        minHeight: '100vh',        
    },
    subTitle: {     
        color: '#00220099',   
        '@media(max-width: 767.98px)': {
            fontSize: '1.0rem',
            lineHeight: '1.8rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.1rem',
            lineHeight: '2.0rem',            
        },
        fontWeight: '300',
        fontSize: '1.2rem',
        lineHeight: '2.2rem',
        fontStyle: 'italic',          
    },
    title: {
        '@media(max-width: 767.98px)': {
            fontSize: '1.4rem',
            lineHeight: '1.9rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '2.4rem',
            lineHeight: '3.0rem',            
        },
        fontWeight: 500,
        fontSize: '3.2rem',
        lineHeight: '3.7rem',                    
    },
    previewImage: {
        '@media(max-width: 767.98px)': {
            marginTop: '1.0rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '2rem',
        },
        marginTop: '3rem',
    },
    teaser: {
        '@media(max-width: 767.98px)': {
            fontSize: '1.3rem',
            lineHeight: '2.1rem',
            marginTop: '2.5rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.7rem',
            lineHeight: '2.5rem',
            marginTop: '5rem',            
        },
        fontWeight: '300',
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
        marginTop: '10rem',        
    },
    textContent: {
        '@media(max-width: 767.98px)': {
            fontSize: '1.0rem',
            lineHeight: '1.8rem',
            marginTop: '1.25rem',
            marginBottom: '2.5rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
            lineHeight: '2.0rem',
            marginTop: '2.5rem',
            marginBottom: '5rem',
        },
        fontWeight: '300',
        fontSize: '1.3rem',
        lineHeight: '2.2rem',
        marginTop: '5rem',
        marginBottom: '10rem',
    }
};
