import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';
import Div100vh from 'react-div-100vh';

import { MenuBar } from './MenuBar';
import { BorderWrapper } from './BorderWrapper';
import { TextMotionDiv } from './TextMotionDiv';
import { BackgroundImage } from './BackgroundImage';
import { ContactInfoBar } from './ContactInfoBar';

import { fetchWPSinglePost, fetchWPDonateInfo, fetchWPImages } from './App';
import { IconInfoBox } from './IconInfoBox';
import SimpleButton from './SimpleButton';

export class DonatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            infoItems: []
        };
    }
    componentDidMount() {
        fetchWPImages().then(function(images){            
            for (const image of images) {
                if (image.title === 'Spendenseite Hintergrundbild') {
                    this.setState({
                        pictureURL: image.pictureURL
                    });
                }
            }
        }.bind(this));
        fetchWPDonateInfo().then(function(info){            
            let infoItems = [];
            for (const entry of info) {
                if (entry.isTitle) {
                    this.setState({
                        title: entry.title,
                        subTitle: entry.description,
                        phoneNumber: entry.phoneNumber,
                        emailAddress: entry.emailAddress
                    });
                } else {                    
                    infoItems.push(entry);
                }
            }            
            infoItems.sort((a, b) => a.order - b.order);
            this.setState({
                infoItems: infoItems 
            });
        }.bind(this));
    }
    render() {
        return (
            <div style={{height: '100%', width: '100%',}}>
                <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>
                <Row className='mt-5 mt-md-5' style={{
                    backgroundColor: '#fff',
                }}/>
                <Row>
                    
                        <div style={{                           
                            '@media(max-width: 767.98px)': {
                                height: '30vh',                                                
                            },    
                            '@media(min-width: 768px) and (max-width: 991.98px)': {
                                height: '40vh'
                            },
                            '@media(min-width: 992px)': {
                                height: '50vh'
                            } 
                        }}>
                            <BackgroundImage                             
                            pictureURL={this.state.pictureURL}/>
                        </div>          
                    
                </Row>
                <BorderWrapper style={styles.content} mdExtraPadding lgExtraPadding>                    
                    <Row className='mt-3 mt-md-5 mt-lg-4 pt-lg-4 mt-xl-5 pt-lg-5'>                    
                        <Col xs={11} md={9} lg={7}>
                            <div style={{
                                '@media(max-width: 767.98px)': {
                                    fontSize: '1.4rem',
                                    lineHeight: '1.9rem',
                                    paddingRight: '0rem'
                                },
                                '@media(min-width: 768px) and (max-width: 991.98px)': {
                                    fontSize: '2.4rem',
                                    lineHeight: '3.0rem',
                                    paddingRight: '0rem'                                   
                                },
                                fontWeight: 500,
                                fontSize: '3.2rem',
                                lineHeight: '3.7rem',
                                paddingRight: '3rem',
                            }}>
                                <TextMotionDiv visible={this.state.title} preset='fadeInUpwards' 
                                whenScrolledIntoViewport>
                                    {this.state.title}
                                </TextMotionDiv>                        
                            </div>
                            <div 
                            className='mt-2 mt-md-4'
                            style={{
                                fontWeight: 300,                                    
                                '@media(max-width: 767.98px)': {         
                                    fontSize: '1.1rem',
                                    lineHeight: '1.6rem',                            
                                },
                                '@media(min-width: 768px) and (max-width: 991.98px)': {
                                    fontSize: '1.25rem',
                                    lineHeight: '1.8rem',                            
                                },
                                '@media(min-width:992px)': {
                                fontSize: '1.5rem',
                                lineHeight: '2.0rem',                        
                                }
                            }}>
                                <TextMotionDiv visible={this.state.title} preset='fadeInUpwards' 
                                whenScrolledIntoViewport>
                                    {this.state.subTitle}
                                </TextMotionDiv>      
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className='mt-4 mt-lg-2'>
                            <TextMotionDiv visible={this.state.title} preset='fadeInUpwards' 
                            whenScrolledIntoViewport>
                                <div className='d-md-inline-block mb-2 mr-md-3'>
                                    <a href={'tel:' + this.state.phoneNumber}>
                                        <SimpleButton caption='Per Telefon spenden'/>
                                    </a>
                                </div>
                                <div className='d-md-inline-block'>
                                    <a href={'mailto:' + this.state.mailAddress}>
                                        <SimpleButton outline caption='Per Mail spenden'/>
                                    </a>
                                </div>
                            </TextMotionDiv>
                        </Col>
                    </Row>
                    <Row className='mt-0 mt-md-3 mt-lg-5 mb-5 pb-lg-5'>
                        {this.state.infoItems && this.state.infoItems.map((item, index) =>
                        <Col xs={12} lg={6}>
                            <div className='mt-5 pt-0 pt-md-1 pt-lg-3'>
                                <IconInfoBox title={item.title} description={item.description}
                                iconStyle={item.iconStyle}/>
                            </div>
                        </Col>
                        )}                        
                    </Row>
                </BorderWrapper>    
                <ContactInfoBar/>
            </div>
        );
    }
}

DonatePage = Radium(DonatePage);

var styles = {
    content: {
        backgroundColor: '#fff',
        minHeight: '100vh',        
    },
    subTitle: {     
        color: '#00220099',   
        '@media(max-width: 767.98px)': {
            fontSize: '1.0rem',
            lineHeight: '1.8rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.1rem',
            lineHeight: '2.0rem',            
        },
        fontWeight: '300',
        fontSize: '1.2rem',
        lineHeight: '2.2rem',
        fontStyle: 'italic',          
    },
    title: {
        '@media(max-width: 767.98px)': {
            fontSize: '1.8rem',
            lineHeight: '2.1rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '3.0rem',
            lineHeight: '3.4rem',            
        },
        fontWeight: '300',
        fontSize: '4.0rem',
        lineHeight: '4.5rem',            
    },
    previewImage: {
        '@media(max-width: 767.98px)': {
            marginTop: '2.5rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '5rem',
        },
        marginTop: '10rem',
    },
    teaser: {
        '@media(max-width: 767.98px)': {
            fontSize: '1.3rem',
            lineHeight: '2.1rem',
            marginTop: '2.5rem',            
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.7rem',
            lineHeight: '2.5rem',
            marginTop: '5rem',            
        },
        fontWeight: '300',
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
        marginTop: '10rem',        
    },
    textContent: {
        '@media(max-width: 767.98px)': {
            fontSize: '1.0rem',
            lineHeight: '1.8rem',
            marginTop: '1.25rem',
            marginBottom: '2.5rem',
        },
        '@media(min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
            lineHeight: '2.0rem',
            marginTop: '2.5rem',
            marginBottom: '5rem',
        },
        fontWeight: '300',
        fontSize: '1.3rem',
        lineHeight: '2.2rem',
        marginTop: '5rem',
        marginBottom: '10rem',
    }
};
