import React from 'react';
import Radium from 'radium';
import { Row } from 'react-bootstrap';

import { TextMotionDiv } from './TextMotionDiv';

import shotIMG from './assets/picture1.jpg';

export class BackgroundImage extends React.Component {
    constructor(props) {
        super(props);     
        this.state = {
            backgroundImageLoaded: false
        };
        this.backgroundImage = new Image();
    }   
    componentDidMount() {
        this.loadBackgroundImage();
    }
    componentDidUpdate(prevProps, prevState) {        
        if (prevProps.pictureURL !== this.props.pictureURL) {            
            this.loadBackgroundImage();
        }
    }
    loadBackgroundImage() {            
        if (this.props.pictureURL) {            
            this.backgroundImage.src = this.props.pictureURL;
            this.backgroundImage.onload = function() {
                console.log('yea');
                this.setState({backgroundImageLoaded: true});        
                if (this.props.onLoad !== undefined) this.props.onLoad();                
            }.bind(this);
        }
    }
    render() {        
        return (                        
            <div style={styles.backgroundWrapper}>  
                <TextMotionDiv style={[{
                    backgroundImage: 'url(' 
                    + (this.backgroundImage.src) + ')',
                    height: this.props.pictureHeight ? this.props.pictureHeight : '100%',
                }, 
                    styles.backgroundImage]} visible={this.state.backgroundImageLoaded || !this.props.pictureURL}
                preset='fadeIn'>                      
                </TextMotionDiv>
            </div>            
        );
    }
}

BackgroundImage = Radium(BackgroundImage);

BackgroundImage.defaultProps = {
    visible: true
};

var styles = {  
    backgroundWrapper: {  
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        zIndex: -100,   
        backgroundColor: '#fff',         
    },
    backgroundImage: {        
        position: 'absolute',
        minWidth: '100%',                             
        backgroundPosition: '50% 50%', 
        backgroundSize: 'cover',        
    },  
};